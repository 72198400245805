<template>
    <div>
        <div class="toolBar mBottom10">
        <el-button type="primary" size='small' plain @click='childDlgShow'>新增</el-button>
        <el-button plain size='small' class='fr' @click="refresh">刷新</el-button>
        </div>
        <el-row :gutter='5'>
            <el-col :md='6'>
                <el-card class="box-card userItem">
                    <div slot="header" class="headerItem">
                        <h3>字典库</h3>
                    </div>
                    <div class="dictionarySearch">
                        <el-input placeholder="请输入名称" suffix-icon="el-icon-search" @input='searchName' clearable
                                  v-model="name" size='small'></el-input>
                    </div>
                    <el-table :data="dictionaryData" highlight-current-row size='small' ref='dictionaryData'
                              @current-change="handleItemClick" style="width: 100%" :height='tableHeight'>
                        <el-table-column type='index' label="序号" align='center'></el-table-column>
                        <el-table-column prop="name" label="名称" align='center'></el-table-column>
                    </el-table>
                    <el-pagination :page-size="15" @current-change="handleCurrentChange"
                                   :current-page.sync="currentPage" layout="prev, pager, next"
                                   :total="totalCount"></el-pagination>
                </el-card>
            </el-col>
            <el-col :md='18'>
                <el-card class="box-card userItem">
                    <div slot="header" class="headerItem">
                        <h3>值域</h3>
                        <!--<div class="buttonItem">-->
                            <!--<el-button type="text" style='margin-right:10px;' @click='childDlgShow'>新增</el-button>-->
                        <!--</div>-->
                    </div>
                    <el-table :data="childData" size='small' style="width: 100%">
                        <el-table-column prop="value" label="值" align='center'></el-table-column>
                        <el-table-column prop="type" label="类别" align='center'></el-table-column>
                        <el-table-column prop="code" label="编码" sortable align='center'></el-table-column>
                        <el-table-column prop="sort" label="序号" sortable align='center'></el-table-column>
                        <el-table-column label="操作" align='center'>
                            <template slot-scope="scope">
                                <el-button @click="handleEdit(scope.row)" style='padding: 0;' type="text" size="small">
                                    修改
                                </el-button>
                                <el-button @click="handleDelete(scope.row.id)" style='color: #F56C6C;padding: 0;'
                                           type="text" size="small">移除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-col>
        </el-row>


        <!--右侧值域操作-->
        <el-dialog v-dialogDrag title="操作" :visible.sync="childDlgVisible" width='40%'>
            <el-form :model="childForm" ref="childForm" label-width="60px" size='small' :rules='childRules'>
                <el-row>
                    <el-col :span='24'>
                        <el-form-item label='名称' prop='name'>
                            <el-input clearable v-model="childForm.name" placeholder="名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span='24'>
                        <el-form-item label='类别' prop='type'>
                            <el-input clearable v-model="childForm.type" placeholder="类别"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span='24'>
                        <el-form-item label='编码' prop='code'>
                            <el-input clearable v-model="childForm.code" placeholder="编码"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span='24'>
                        <el-form-item label='值' prop='value'>
                            <el-input clearable v-model="childForm.value" placeholder="值"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span='24'>
                        <el-form-item label='序号' prop='sort'>
                            <el-input clearable v-model="childForm.sort" placeholder="序号"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
				<el-button size="small" @click="childDlgVisible = false">取 消</el-button>
				<el-button size="small" type="primary" @click="handleDictionary">保存</el-button>
			</span>
        </el-dialog>
    </div>
</template>

<script>
    class ChildForm {
        constructor() {
            this.code = null;
            this.id = null;
            this.name = null;
            this.sort = null;
            this.type = null;
            this.value = null;
        }

        reset() {
            this.constructor();
        }
    };


    export default {
        name: 'dictionary',
        data() {
            return {
                currentPage: 1,
                totalCount: 0,
                name: null,
                tableHeight: document.documentElement.clientHeight - 310,
                dictionaryData: [],
                childData: [],
                currentItem: {},


                childDlgVisible: false,
                childForm: new ChildForm(),
                childRules: {
                    name: [{required: true, message: '请输入名称', trigger: 'blur'}],
                    type: [{required: true, message: '请输入类型', trigger: 'blur'}],
                    code: [{required: true, message: '请输入编码', trigger: 'blur'}],
                    value: [{required: true, message: '请输入值', trigger: 'blur'}],
                    sort: [{required: true, message: '请输入序号', trigger: 'blur'}]
                }
            }
        },
        computed: {

        },
        methods: {
            searchName() {
                this.currentPage = 1;
                this.getDictionaryList();
                this.childData=[];
            },
            async getDictionaryList() {
                let paramObj = {
                    name: this.name,
                    limit: 15,
                    offSet: (this.currentPage - 1) * 15,
                };
                this.dictionaryData = [];
                let res = await this.$http.post('/sys/dictionaryList', paramObj);
                if (res.state) {
                    if (res.data && res.data.list.length) {
                        this.dictionaryData = this.deepClone(res.data.list);
                        this.totalCount = res.data.totalCount;
                        let selectData = this.dictionaryData[0];
                        if (this.currentItem.type) {
                            selectData = this.dictionaryData.find(item => item.type == this.currentItem.type);
                        }
                        this.$refs.dictionaryData.setCurrentRow(selectData);
                    }
                }
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.getDictionaryList();
            },
            handleItemClick(data) {
                if (!data) {
                    return false;
                }
                this.currentItem = Object.assign({}, data);
                this.getChildList(data.type);

            },
            async getChildList(type) {
                let res = await this.$http.post('/sys/dictionaryChildList', {type});
                if (res.state) {
                    this.childData = res.data;
                }
            },
            resetForm(formName) {
                if (this.$refs[formName] !== undefined) {
                    this.$refs[formName].resetFields();
                }
            },
            childDlgShow() {
                this.childDlgVisible = true;
                this.resetForm('childForm');
                this.childForm.reset();

            },
            handleEdit(row) {
                this.resetForm('childForm');
                this.childForm.reset();
                this.childDlgVisible = true;
                Object.assign(this.childForm, row);
                this.childForm.name = this.currentItem.name;
            },
            handleDictionary() {
                this.$refs['childForm'].validate((valid) => {
                    if (valid) {
                        let requestUrl = 'sys/addDictionary';
                        if (this.childForm.id) {
                            requestUrl = 'sys/updateDictionary';
                        }
                        this.handleDic(requestUrl, this.childForm)
                    } else {
                        console.log('error submit');
                        return false;
                    }
                })

            },
            async handleDic(requestUrl, childForm) {
                let res = await this.$http.post(requestUrl, childForm);
                if (res.state) {
                    this.$message.success('操作成功');
                    this.childDlgVisible = false;
                    this.getDictionaryList();
                }
            },
            handleDelete(id) {
                this.$confirm('是否移除该项?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.deleteDic(id);
                }).catch(() => {
                    console.log('取消移除!');
                });
            },
            async deleteDic(id) {
                let res = await this.$http.post('/sys/delDictionary', {id: id, type: this.currentItem.type});
                if (res.state) {
                    this.getChildList(this.currentItem.type);
                    this.$message.success('移除成功！');
                }
            },
            refresh(){
                this.getDictionaryList();
            }
        },
        mounted() {
            window.onresize = () => {
                window.fullHeight = document.documentElement.clientHeight;
                this.tableHeight = window.fullHeight - 310;
            };
            this.getDictionaryList();
        }
    }
</script>

<style scoped>
    .userItem {
        height: calc(100vh - 179px);
    }

    .headerItem {
        text-align: center;
        height: 40px;
        line-height: 40px;
        position: relative;
    }

    .headerItem .buttonItem {
        position: absolute;
        top: 0;
        right: 10px;
    }

    .headerItem h3 {
        font-weight: normal;
    }
</style>
<style>
    .userItem .el-card__header {
        padding: 0;
    }

    .dictionarySearch .el-input__inner {
        border-radius: 20px;
    }
</style>